'use client';

import { IconX } from '@tabler/icons-react';
import React, {
	useCallback,
	MouseEventHandler,
	ReactNode,
	useEffect,
	useRef,
	forwardRef,
	useImperativeHandle,
} from 'react';
import ReactDOMServer from 'react-dom/server';

export type ModalProps = {
	modalId: string;
	className?: string;
	closeOnClickOutside?: boolean;
	closeOnEsc?: boolean;
	children?: ReactNode;
	title?: ReactNode;
	onClose?: (modalId: string) => void;
	showClose?: boolean;
};

export type ModalImperativeRef = {
	close: () => void;
	setTitle: (title: ReactNode) => void;
};

export default forwardRef<ModalImperativeRef, ModalProps>(function Modal(props, ref) {
	const { className, children, modalId, title, onClose, closeOnClickOutside, closeOnEsc } = props;
	const showClose = props.showClose ?? true;

	const elRef = useRef<HTMLDivElement>(null);

	const closeModal = useCallback(() => {
		if (elRef.current) {
			elRef.current.classList.add('closing');
			setTimeout(() => {
				onClose?.(modalId);
			}, 60);
		} else {
			onClose?.(modalId);
		}
	}, [onClose, modalId]);

	useImperativeHandle(
		ref,
		() => ({
			close: closeModal,
			setTitle: (title: ReactNode) => {
				const modal = elRef.current;
				if (modal) {
					const header = modal.querySelector('.header h4');
					if (header) {
						header.innerHTML = ReactDOMServer.renderToStaticMarkup(<>{title}</>);
					}
				}
			},
		}),
		[closeModal],
	);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (closeOnEsc && e.key === 'Escape') {
				e.preventDefault();
				e.stopPropagation();
				closeModal();
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [closeModal, closeOnEsc]);

	const handleOverlayClicked: MouseEventHandler<HTMLDivElement> = (e) => {
		if (closeOnClickOutside) {
			e.preventDefault();
			e.stopPropagation();
			closeModal();
		}
	};

	const handleCloseButtonClicked: MouseEventHandler<SVGSVGElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		closeModal();
	};

	return (
		<div ref={elRef} id={modalId} className={`Modal ${className ?? ''}`}>
			<div className="overlay" onClick={handleOverlayClicked}></div>
			<div className="inner light-bg">
				<div className="content">
					{title || showClose ? (
						<div className="header">
							<h4>{title}</h4>
							{showClose && <IconX onClick={handleCloseButtonClicked} />}
						</div>
					) : null}
					{children ? <div className="body">{children}</div> : null}
				</div>
			</div>
		</div>
	);
});
