'use client';

import React, { ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Modal, { ModalImperativeRef, ModalProps } from './Modal';
import { v4 as uuid } from 'uuid';

export type ModalProviderValue = {
	modals: ModalProps[];
	setModalTitle: (modalId: string, title: ReactNode) => void;
	openModal: (modal: ModalProps) => string;
	closeModal: (modalId: string) => void;
	closeAllModals: () => void;
};

export const ModalProviderContext = createContext<ModalProviderValue | null>(null);

export type ModalProviderProps = {
	children?: ReactNode;
	modals?: ModalProps[];
};

export default function ModalProvider(props: ModalProviderProps) {
	const { children } = props;

	const modalRefs = useRef<Map<string, ModalImperativeRef>>(new Map());
	const [modals, setModals] = useState<ModalProps[]>(props.modals ?? []);

	const openModal = (modal: ModalProps) => {
		if (!modal.modalId) {
			modal.modalId = uuid();
		}
		setModals((current) => [...current, modal]);
		return modal.modalId;
	};

	const handleModalClosed = useCallback(
		(modalId: string) => {
			const modalProps = modals.find((modal) => modal.modalId === modalId);
			modalProps?.onClose?.(modalId);
			setModals((current) => current.filter((modal) => modal.modalId !== modalId));
		},
		[modals],
	);

	const closeModal = useCallback((modalId: string) => {
		const modal = modalRefs.current.get(modalId);
		if (modal) {
			modal.close();
		}
	}, []);

	const closeAllModals = useCallback(() => {
		modals.forEach((modal) => {
			const modalRef = modalRefs.current.get(modal.modalId);
			if (modalRef) {
				modalRef.close();
			}
		});
	}, [modals]);

	const setModalRef = (modalId: string, ref: ModalImperativeRef | null) => {
		if (!ref) {
			modalRefs.current.delete(modalId);
		} else {
			modalRefs.current.set(modalId, ref);
		}
	};

	const setModalTitle = (modalId: string, title: ReactNode) => {
		const modalRef = modalRefs.current.get(modalId);
		if (modalRef) {
			modalRef.setTitle(title);
		}
	};

	useEffect(() => {
		if (modals.length > 0) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
	}, [modals.length]);

	const value: ModalProviderValue = {
		modals,
		setModalTitle,
		openModal,
		closeModal,
		closeAllModals,
	};

	return (
		<ModalProviderContext.Provider value={value}>
			{children}
			<div className="ModalProvider">
				{modals.map((props) => {
					return (
						<Modal
							key={props.modalId}
							{...props}
							onClose={handleModalClosed}
							ref={(ref) => setModalRef(props.modalId, ref)}
						/>
					);
				})}
			</div>
		</ModalProviderContext.Provider>
	);
}

export function useModalProvider() {
	const context = useContext(ModalProviderContext);

	if (!context) {
		throw new Error('useModalProvider must be used within an ModalProvider');
	}

	return context;
}

export function useModal(modalId: string) {
	const modalProvider = useModalProvider();
	const modal = modalProvider.modals.find((modal) => modal.modalId === modalId);
	if (!modal) {
		throw new Error(`Modal with id "${modalId}" not found.`);
	}
	return modal;
}
