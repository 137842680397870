import { useMemo } from 'react';
import useAbortRegistry from '../Hooks/useAbortRegistry';
import { User } from '@dr-pam/common-types/database';
import FetchUtils, { AbortableRequest } from '../Utils/FetchUtils';
import type { SimpleAddress } from './GoogleMapsService';
import { NoUndefinedField } from '../Types';
import { RegisterAbortFunction } from '../Utils/AbortUtils';
import type {
	CreateSavedForLater,
	MarkArticleAsRead,
	MarkResourceAsDownloaded,
	SavedForLaterWithRelations,
} from './Server/UserService';
import { UserSubscriptionWithDetails } from '../Models/User';

export type PublicProfileUpdate = Partial<
	Pick<
		User,
		'id' | 'isPublicEnabled' | 'publicName' | 'publicDiscipline' | 'publicAddresses' | 'publicIsTelehealthAvailable'
	>
>;
export type BillingDetailsUpdate = Pick<User, 'id' | 'billingAddress' | 'billingCountryCode'>;
export type ProfileUpdate = Pick<
	User,
	'id' | 'displayName' | 'fullName' | 'photoUrl' | 'healthDiscipline' | 'registrationNumber' | 'regulatoryBody'
>;
export type PasswordUpdate = Pick<User, 'id'> & { password: string };
export type EmailUpdate = Pick<User, 'id' | 'email'>;
export type AccountDetailsUpdate = Pick<User, 'id' | 'displayName' | 'fullName'> & {
	password?: string;
};

export type SignupUserRequest = NoUndefinedField<
	Pick<
		User,
		| 'fullName'
		| 'email'
		| 'billingCountryCode'
		| 'healthDiscipline'
		| 'regulatoryBody'
		| 'registrationNumber'
		| 'howDidYouHear'
	>
> & {
	password: string;
	billingAddress: SimpleAddress | null;
	subscribeToNewsletter?: boolean;
};

export default class UserService {
	constructor(private readonly _registerAbort?: RegisterAbortFunction) {}

	public updateAccountDetails(user: AccountDetailsUpdate): AbortableRequest<User> {
		const request = FetchUtils.putJson<User, AccountDetailsUpdate>('/api/user/me', user);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public updateProfile(user: ProfileUpdate | PublicProfileUpdate | BillingDetailsUpdate): AbortableRequest<User> {
		const request = FetchUtils.putJson<User, ProfileUpdate | PublicProfileUpdate>('/api/user/me', user);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public updatePassword(user: PasswordUpdate): AbortableRequest<User> {
		const request = FetchUtils.putJson<User, PasswordUpdate>('/api/user/me', user);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public updateEmail(user: EmailUpdate): AbortableRequest<User> {
		const request = FetchUtils.putJson<User, EmailUpdate>('/api/user/me', user);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public signup(user: SignupUserRequest): AbortableRequest<User> {
		const request = FetchUtils.postJson<User, SignupUserRequest>('/api/user/signup', user);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getSavedForLater(): AbortableRequest<SavedForLaterWithRelations> {
		const request = FetchUtils.getJson<SavedForLaterWithRelations>(`/api/user/me/saved-for-later`);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public addSavedForLater(savedForLater: CreateSavedForLater): AbortableRequest<SavedForLaterWithRelations> {
		const request = FetchUtils.putJson<SavedForLaterWithRelations, CreateSavedForLater>(
			`/api/user/me/saved-for-later`,
			savedForLater,
		);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public removeSavedForLater(id: string): AbortableRequest<void> {
		const request = FetchUtils.deleteJson<void>(`/api/user/me/saved-for-later/${id}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public markArticleAsRead(articleId: string) {
		const request = FetchUtils.postJson<unknown, MarkArticleAsRead>(`/api/user/me/article/read`, {
			articleId,
			read: true,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public unmarkArticleAsRead(articleId: string) {
		const request = FetchUtils.postJson<unknown, MarkArticleAsRead>(`/api/user/me/article/read`, {
			articleId,
			read: false,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public markResourceAsDownloaded(resourceId: string) {
		const request = FetchUtils.postJson<unknown, MarkResourceAsDownloaded>(`/api/user/me/resource/download`, {
			resourceId,
			downloaded: true,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public unmarkResourceAsDownloaded(resourceId: string) {
		const request = FetchUtils.postJson<unknown, MarkResourceAsDownloaded>(`/api/user/me/resource/download`, {
			resourceId,
			downloaded: false,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getSubscriptions() {
		const request = FetchUtils.getJson<UserSubscriptionWithDetails[]>(`/api/user/me/subscription`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public cancelSubscription(userSubscriptionId: string) {
		const request = FetchUtils.putJson<UserSubscriptionWithDetails, { isCancelled: boolean }>(
			`/api/user/me/subscription/${userSubscriptionId}`,
			{
				isCancelled: true,
			},
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public reactivateSubscription(userSubscriptionId: string) {
		const request = FetchUtils.putJson<UserSubscriptionWithDetails, { isCancelled: boolean }>(
			`/api/user/me/subscription/${userSubscriptionId}`,
			{
				isCancelled: false,
			},
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
}

export function useUserService() {
	const registerAbort = useAbortRegistry();

	return useMemo(() => new UserService(registerAbort), [registerAbort]);
}
